export default [
    {
        headerName: 'Id',
        field: 'id',
        width: 350,
    },
    {
        headerName: 'Username',
        field: 'username',
    },
    {
        headerName: 'Email',
        field: 'email',
    },
    {
        headerName: 'First Name',
        field: 'firstName',
    },
    {
        headerName: 'Last Name',
        field: 'lastName',
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
    },
    {
        headerName: 'Suffix',
        field: 'suffix',
    },
    {
        headerName: 'Type',
        field: 'type',
    },
    {
        headerName: 'Birth Date',
        field: 'birthDate',
    },
    {
        headerName: 'Active',
        field: 'active',
        filter: false,
    },
    {
        headerName: 'District User Id',
        field: 'districtUserId',
    },
    {
        headerName: 'Created At',
        field: 'createdAt',
        filter: false,
    },
    {
        headerName: 'Updated At',
        field: 'updatedAt',
        filter: false,
    },
]
