<template>
    <v-container fluid>
        <client-picker v-model="client" data-cy="account-service-client-picker" />
        <ag-grid-server-side
            v-if="id"
            id="account-management"
            :key="id"
            data-cy="account-service-ag-grid"
            :server-side-datasource="serverSideDatasource"
            :column-defs="columnDefs"
        />
    </v-container>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/imt/accounts'
import pagination from '@/helpers/ag-grid/pagination/rest'
import { mapGetters, mapActions } from 'vuex'
import ClientPicker from '@/components/ClientPicker'

export default {
    name: 'AccountServiceView',
    components: {
        ClientPicker,
        AgGridServerSide,
    },
    props: {
        id: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        columnDefs: ColumnDef,
        client: null,
    }),
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            items: 'account/getItems',
            metadata: 'account/getMetadata',
            getClientById: 'client/getItemById',
        }),
    },
    watch: {
        id(id) {
            this.loadClient(id)
        },
    },
    mounted() {
        this.loadClient(this.id)
    },
    methods: {
        async loadClient(id) {
            if (!id) {
                return
            }

            this.client = this.getClientById(id)

            // if client hasn't been loaded into vuex, fetch the client.
            // in the future, we should probably update client picker to use a
            // item-value and do most of this work in the picker itself.
            if (!this.client) {
                await this.fetchClient(id)
                this.client = this.getClientById(id)
            }
        },
        ...mapActions({
            fetchAccount: 'account/get',
            fetchAccounts: 'account/cget',
            fetchClient: 'client/get',
        }),
        serverSideDatasource: function () {
            const fetch = async ({ id, ...params }) => {
                if (id) {
                    // fetch account by id
                    await this.fetchAccount({ clientId: this.id, id })
                    return { items: this.items, count: 1 }
                }

                // fetch accounts by filter params
                await this.fetchAccounts({
                    clientId: this.id,
                    params,
                })
                return { items: this.items, count: this.metadata.count }
            }

            return {
                getRows: async function (params) {
                    const { items, count } = await fetch(pagination(params))
                    params.successCallback(items, count)
                },
            }
        },
    },
}
</script>
